import state from "@/state";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { transitionDefaults } from "lib/constants";
import { getHref } from "lib/helpers";
import { useSession } from "next-auth/react";
import Link from "next/link";
import React from "react";
import { ArrowRight, X } from "react-feather";
import { useKey } from "react-use";

const overlayVariants = {
  hidden: { opacity: 0, transition: transitionDefaults },
  show: { opacity: 1, transition: transitionDefaults },
};

const rootVariants = {
  hidden: { x: "100%", transition: transitionDefaults },
  show: { x: "0%", transition: transitionDefaults },
};

const contentVariants = {
  hidden: {
    x: "20%",
    opacity: 0,
    transition: transitionDefaults,
  },
  show: {
    x: "0%",
    opacity: 1,
    transition: transitionDefaults,
  },
};

const itemsVariants = {
  hidden: {
    transition: {
      ...transitionDefaults,
    },
  },
  show: {
    transition: {
      ...transitionDefaults,
      staggerChildren: 0.0125,
      delayChildren: 0.125,
    },
  },
};

const itemVariants = {
  hidden: {
    x: "10%",
    opacity: 0,
    transition: transitionDefaults,
  },
  show: {
    x: "0%",
    opacity: 1,
    transition: transitionDefaults,
  },
};

const Menu = ({ items, site }) => {
  const closeMenu = () => {
    state.menu.isOpen = false;
  };

  useKey("Escape", closeMenu);

  return (
    <>
      <motion.div
        className={clsx(
          "fixed bottom-0 right-0 top-0 z-40 w-[calc(100%-3rem)] bg-sky-500 text-white md:w-1/2 2xl:w-1/4",
        )}
        variants={rootVariants}
        initial="hidden"
        animate="show"
        exit="hidden"
      >
        <button
          className={clsx("absolute right-3 top-3 z-50 h-10 w-10 rounded-full")}
          onClick={closeMenu}
        >
          <X size={28} strokeWidth={1.5} />
        </button>

        <motion.div
          className={clsx("flex h-full w-full flex-col p-8 pt-16")}
          variants={contentVariants}
        >
          <_Items items={items} />
          <_LoginButton site={site} />
        </motion.div>
      </motion.div>
    </>
  );
};

const _LoginButton = ({ site }) => {
  const { data: session, status } = useSession();

  const isClientSite = site !== "default";

  return (
    <>
      {isClientSite && !session && site !== "themodernhouse" && (
        <div className={clsx("mt-auto block")}>
          <Link
            href="/auth/signup"
            className={clsx(
              "flex h-12 items-center whitespace-nowrap rounded-full bg-gray-900 px-6 font-medium leading-none text-white transition-opacity duration-300 ease-out hover:opacity-75",
            )}
          >
            <span>Set up your Login</span>
            <span className={clsx("ml-auto")}>
              <ArrowRight
                className={clsx("ml-auto h-5 w-5")}
                strokeWidth={1.5}
              />
            </span>
          </Link>
        </div>
      )}
    </>
  );
};

const _Items = ({ items }) => {
  return (
    <motion.ul className={clsx("")} variants={itemsVariants}>
      {items.map((item) => {
        return <_Item key={item.id} item={item} />;
      })}
    </motion.ul>
  );
};

const _Item = ({ item }) => {
  const href = item.element ? getHref(item.element) : null;
  const hasHref = !!href;

  return (
    <motion.li className={clsx("")} variants={itemVariants}>
      <div className={clsx("text-xl font-medium")}>
        {hasHref && (
          <Link href={href} className={clsx("")}>
            {item.title}
          </Link>
        )}
        {!hasHref && <div className={clsx("")}>{item.title}</div>}
      </div>
    </motion.li>
  );
};

const _Overlay = () => {
  const handleClick = () => {
    state.menu.isOpen = false;
  };

  return (
    <AnimatePresence>
      {state.menu.isOpen && (
        <motion.div
          className={clsx("fixed inset-0 z-30 bg-black/30")}
          variants={overlayVariants}
          initial="hidden"
          animate="show"
          exit="hidden"
          onClick={handleClick}
        />
      )}
    </AnimatePresence>
  );
};

export default Menu;
